<template>
  <div>
    <div class="bg">
      <div class="content">
        <div class="video">
          <video width="380" height="210" style="background:black" controls controlsList="nodownload" :src="url" type="video/mp4" :key="url"></video>
        </div>
        <div class="list">
          <div v-for=" (item,i) in videoList" :key="i">
            <el-row class="item">
              <el-col :span="8" class="img"> </el-col>
              <el-col :span="15" :offset="1">
                <div @click="changeVideo(item)" :class="{act: item.url == url }">{{item.name}}</div>
              </el-col>
            </el-row>
          </div>
        </div>

      </div>
    </div>
    <div class="page guide">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="nav">
        <el-tab-pane label="会员卡激活" name="first" style="padding:20px;background:#e1e1e1;margin-bottom:20px">
          <img src="@/assets/img/guide/guide10.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide11.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide12.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide13.jpg" alt="" class="img_">
        </el-tab-pane>
        <el-tab-pane label="注册登录" name="second" style="padding:20px;background:#e1e1e1;margin-bottom:20px">
          <img src="@/assets/img/guide/guide1.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide2.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide3.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide4.jpg" alt="" class="img_">
        </el-tab-pane>
        <el-tab-pane label="智能查询" name="third" style="padding:20px;background:#e1e1e1;margin-bottom:20px">
          <img src="@/assets/img/guide/guide5.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide6.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide7.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide8.jpg" alt="" class="img_">
          <img src="@/assets/img/guide/guide9.jpg" alt="" class="img_">
        </el-tab-pane>
        <el-tab-pane label="测评教程" name="fourth" style="padding:20px;background:#e1e1e1;margin-bottom:20px"><img src="@/assets/img/guide/guide15.jpg" alt="" class="img_"></el-tab-pane>
        <el-tab-pane label="填报教程" name="five" style="padding:20px;background:#e1e1e1;margin-bottom:20px"><img src="@/assets/img/guide/guide16.jpg" alt="" class="img_"></el-tab-pane>
      </el-tabs>
    </div>
  </div>

</template>

<script>
export default {
  name: "",
  data() {
    return {
      activeName: "first",
      url: "https://img.bkgaoshou.com/video/河南省高考志愿填报指南院校篇介绍.mp4",
      videoList: [
        { name: "河南省高考志愿填报指南院校篇介绍", url: "" },
        { name: "河南省高考志愿填报指南院校篇介绍", url: "" },
      ],
    };
  },
  created() {
    this.getDate();
  },
  methods: {
    getDate() {
      this.$fecth.post("bk_video/getData").then((res) => {
        this.videoList = res;
      });
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },
    changeVideo(item) {
      console.log(this.url);
      this.url = item.url;
      console.log(this.url);
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang='less'>
.nav {
  margin-top: 20px;
}
.img_ {
  width: 100%;
}
.act{
  color: #009cff;
}
.bg {
  height: 291px;
  overflow: hidden;
  background: url("../assets/img/help.png") center no-repeat;
  .content {
    margin: 40px auto;
    width: 630px;
    height: 300px;
    .video {
      display: inline-block;
    }
    .list {
      padding-top: 10px;
      display: inline-block;
      width: 249px;
      height: 200px;
      background: black;
      vertical-align: bottom;
      position: relative;
      top: -4px;
      left: 1px;
      // overflow: scroll;
      // overflow-x: hidden;
      color: white;

      .item {
        padding: 10px;
        .img {
          padding: 12px;
          background: rgb(132, 132, 156);
          width: 70px;
          height: 40px;
        }
      }
      .item:hover {
        color: #009cff;
      }
    }
  }
}
</style>

<style  lang='less'>
.guide {
  .el-tabs__item {
    width: 238px;
    font-size: 18px;
  }
}
</style>